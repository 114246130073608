import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Paper } from '@mui/material';
import { sendPasswordResetEmail } from './firebaseConfig';

function ForgotPassword({ onBack, setNotification }) {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(email);
      setNotification({
        open: true,
        message: 'Password reset email sent. Check your inbox.',
        severity: 'success',
      });
      onBack(); // Close the ForgotPassword component
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setNotification({
        open: true,
        message: 'Failed to send password reset email. Please try again.',
        severity: 'error',
      });
    }
  };

  return (
    <Box
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onBack();
        }
      }}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, margin: 'auto' }}>
        <Typography variant="h5" gutterBottom align="center">
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button 
            variant="contained" 
            color="primary" 
            type="submit"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          >
            Send Reset Email
          </Button>
        </form>
        <Button 
          variant="text" 
          onClick={onBack}
          fullWidth
          sx={{ mt: 2 }}
        >
          Back to Sign In
        </Button>
      </Paper>
    </Box>
  );
}

export default ForgotPassword;
