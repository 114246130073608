/* global gtag, gtagSendEvent */

import React, { useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';

export default function StripePricing() {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    const handlePriceClick = (event) => {
      gtag('event', 'price_click', {
        'event_category': 'Pricing',
        'event_label': event.detail.price.nickname
      });
    };

    const handlePlanSelect = (event) => {
      gtag('event', 'plan_selected', {
        'event_category': 'Pricing',
        'event_label': event.detail.price.nickname
      });
    };

    document.addEventListener('stripe-pricing-table:price-click', handlePriceClick);
    document.addEventListener('stripe-pricing-table:price-selected', handlePlanSelect);

    return () => {
      document.body.removeChild(script);
      document.removeEventListener('stripe-pricing-table:price-click', handlePriceClick);
      document.removeEventListener('stripe-pricing-table:price-selected', handlePlanSelect);
    };
  }, []);

  if (!currentUser) {
    return <div>Please sign in to view pricing options.</div>;
  }

  return (
    <div className="pricing-table-container">
      <stripe-pricing-table
        pricing-table-id="prctbl_1Q0FArBk4UsbAXWhFu7ec3Rn"
        publishable-key="pk_live_51MMdUqBk4UsbAXWhT6E6PA3VKOC650eYufHFcPLoeusglWoF5GjSP6H5mMRRPKjaOsAI87qbs4yqyIuTuaCbkeT400ZMsrzbMH"
        client-reference-id={currentUser.uid}
        customer-email={currentUser.email}
        success-url={`https://www.webmonitor.fyi?userId=${currentUser.uid}`}
        cancel-url={`https://www.webmonitor.fyi?userId=${currentUser.uid}`}
        onClick={() => gtagSendEvent('https://www.webmonitor.fyi')}
      ></stripe-pricing-table>
    </div>
  );
}
