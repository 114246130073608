import React, { useState, useContext, useEffect } from 'react';
import { List, ListItem, ListItemText, Button, Stack, Card, CardContent, Typography, Collapse, Paper, Box } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LinkIcon from '@mui/icons-material/Link';
import { AuthContext } from './AuthContext';
import StripePricing from './StripePricing';

const frequencyMap = {
  "1": "Every minute",
  "60": "Every hour",
  "360": "Every 6 hours",
  "1440": "Every day"
};

function JobList({ jobs, setJobs, subscriptionLevel, onNotification }) {
  console.log('JobList render - Current subscription level:', subscriptionLevel);
  console.log('JobList render - Jobs:', jobs);

  const { currentUser } = useContext(AuthContext);
  const [openJobIds, setOpenJobIds] = useState([]);
  const [error] = useState(null);
  const [showPricing, setShowPricing] = useState(false);

  const handleToggle = (id) => {
    setOpenJobIds((prevOpen) =>
      prevOpen.includes(id) ? prevOpen.filter((jobId) => jobId !== id) : [...prevOpen, id]
    );
  };

  const toggleJobStatus = async (jobId) => {
    try {
      console.log(`Attempting to toggle job status for job ID: ${jobId}`);
      const token = await currentUser.getIdToken();
      const url = `${process.env.REACT_APP_API_URL}/jobs/${jobId}/toggle`;
      console.log('Request URL:', url);
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(`Response status: ${response.status}`);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        throw new Error(errorData.error || 'Failed to toggle job status');
      }

      const responseData = await response.json();
      console.log('Response data:', responseData);

      setJobs(jobs.map(job => job.id === jobId ? { ...job, status: responseData.status } : job));
      onNotification({
        open: true,
        message: responseData.message || `Job ${responseData.status === 'Active' ? 'activated' : 'deactivated'} successfully`,
        severity: 'success',
      });
    } catch (error) {
      console.error('Error toggling job status:', error);
      onNotification({
        open: true,
        message: `Failed to toggle job status: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const deleteJob = async (jobId) => {
    try {
      console.log(`Attempting to delete job with ID: ${jobId}`);
      const token = await currentUser.getIdToken();
      const url = `${process.env.REACT_APP_API_URL}/jobs/${jobId}`;
      console.log('Delete request URL:', url);
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(`Delete response status: ${response.status}`);
      
      if (!response.ok) {
        const errorData = await response.text();
        console.error('Delete error response:', errorData);
        throw new Error(errorData || 'Failed to delete job');
      }

      const responseData = await response.json();
      console.log('Delete response data:', responseData);

      setJobs(jobs.filter(job => job.id !== jobId));
      onNotification({
        open: true,
        message: 'Job deleted successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting job:', error);
      onNotification({
        open: true,
        message: `Failed to delete job: ${error.message}`,
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    console.log('useEffect triggered. Subscription level:', subscriptionLevel);
    console.log('Jobs:', jobs);
    
    const stoppedJobs = jobs.filter(job => job.status === 'Stopped');
    console.log('Jobs with stopped status:', stoppedJobs.length);
    console.log('Stopped jobs:', stoppedJobs);
    
    if (subscriptionLevel === 'Unregistered') {
      console.log('Conditions met to show pricing');
      const timer = setTimeout(() => {
        console.log('Setting showPricing to true');
        setShowPricing(true);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      console.log('Conditions not met to show pricing');
      console.log('Subscription level:', subscriptionLevel);
      setShowPricing(false);
    }
  }, [subscriptionLevel, jobs]);

  const handleSubscriptionSuccess = () => {
    setShowPricing(false);
    onNotification({
      open: true,
      message: 'Subscription successful! Your jobs will now continue monitoring.',
      severity: 'success',
    });
  };

  return (
    <Stack spacing={3}>
      {error && <Typography color="error">{error}</Typography>}
      {jobs.map((job) => {
        console.log(`Job ${job.id} status:`, job.status);
        return (
          <Card key={job.id} style={{ width: '100%' }}>
            <CardContent>
              <Box display="flex" alignItems="center" flexWrap="wrap" className="job-details">
                <Box sx={{ flex: '0 0 200px', display: 'flex', alignItems: 'center' }}>
                  <LinkIcon className="link-icon" fontSize="small" />
                  <Typography variant="body1" className="job-url-text" sx={{ ml: 1 }}>
                    <a
                      href={job.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="job-url-link"
                    >
                      {new URL(job.url).hostname.replace(/^www\./, '')}
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                    <strong>Frequency:</strong> {frequencyMap[job.frequency] || `${job.frequency} minutes`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                    <strong>Count:</strong> {job.analysisHistory ? job.analysisHistory.length : 0}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mr: 2, maxWidth: '300px', wordBreak: 'break-word' }}>
                    <strong>Criteria:</strong> {job.info}
                  </Typography>
                </Box>
              </Box>
              
              <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color={job.status === 'Active' ? 'error' : 'primary'}
                    onClick={() => toggleJobStatus(job.id)}
                    disabled={subscriptionLevel === 'Unregistered'}
                    className="job-button"
                  >
                    {job.status === 'Active' ? 'Stop' : 'Start'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => deleteJob(job.id)}
                    className="job-button"
                  >
                    Delete
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => handleToggle(job.id)}
                    endIcon={openJobIds.includes(job.id) ? <ExpandLess /> : <ExpandMore />}
                    className="job-button"
                  >
                    {openJobIds.includes(job.id) ? 'Hide Analysis' : 'Show Analysis'}
                  </Button>
                </Stack>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  {(!job.analysisHistory || job.analysisHistory.length === 0) && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                        Pending
                      </Typography>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: 'primary.main',
                          animation: 'pulse 1.5s infinite ease-in-out',
                          '@keyframes pulse': {
                            '0%': {
                              transform: 'scale(0.95)',
                              boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
                            },
                            '70%': {
                              transform: 'scale(1)',
                              boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
                            },
                            '100%': {
                              transform: 'scale(0.95)',
                              boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Stack>

              {subscriptionLevel === 'Unregistered' && job.status === 'Stopped' && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  Job executed. Please subscribe to continue monitoring and receive email alerts.
                </Typography>
              )}

              {job.analysisHistory !== undefined && (
                <Collapse in={openJobIds.includes(job.id)} timeout="auto" unmountOnExit>
                  <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
                    History:
                  </Typography>
                  {job.analysisHistory && job.analysisHistory.length > 0 ? (
                    <List>
                      {job.analysisHistory.map((history, index) => (
                        <ListItem key={index}>
                          <Paper
                            elevation={2}
                            style={{
                              padding: '10px',
                              width: '100%',
                              backgroundColor: history.alert ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 128, 0, 0.1)',
                            }}
                          >
                            <ListItemText
                              primary={history.result}
                              secondary={new Date(history.timestamp).toLocaleString()}
                            />
                          </Paper>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                      Pending
                    </Typography>
                  )}
                </Collapse>
              )}
              
              {/* Removed the "Latest Analysis:" section */}
            </CardContent>
          </Card>
        );
      })}
      
      {showPricing ? (
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Subscribe to Continue Monitoring
          </Typography>
          <StripePricing userId={currentUser.uid} onSubscriptionSuccess={handleSubscriptionSuccess} />
        </Box>
      ) : (
        null
      )}
      {console.log('Render: showPricing =', showPricing)}
    </Stack>
  );
}

export default JobList;
