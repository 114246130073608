import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Container, Typography, Paper, Box, Snackbar, Alert, TextField, Button, Divider, Link, ToggleButtonGroup, ToggleButton, FormControlLabel, Checkbox, InputAdornment, IconButton, Accordion, AccordionSummary, AccordionDetails, Grid, useTheme } from '@mui/material';
import { Visibility, VisibilityOff, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import GoogleButton from 'react-google-button';
import JobForm from './JobForm';
import JobList from './JobList';
import ForgotPassword from './ForgotPassword';
import {
  DollarSign,
  Briefcase,
  Package,
  Newspaper,
  Calendar,
  Rocket,
  FileText,
  Bell,
} from 'lucide-react';
import { auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, googleProvider, signInWithPopup } from './firebaseConfig';
import { AuthContext, AuthProvider } from './AuthContext';
import useWebSocket from './hooks/useWebSocket';

// Add this import for Firebase email verification
import { sendEmailVerification } from "firebase/auth";

const API_URL = process.env.REACT_APP_API_URL || 'https://app.webmonitor.fyi/api';
const WS_URL = process.env.REACT_APP_WS_URL || `wss://app.webmonitor.fyi/ws`;

const useCases = [
  { icon: DollarSign, title: 'Price Drops', description: 'Alert when price drops below $X' },
  { icon: Briefcase, title: 'Job Listings', description: 'Notify when "Job Title" is listed' },
  { icon: Package, title: 'Product Availability', description: 'Get alerts when items are restocked' },
  { icon: Newspaper, title: 'New Articles', description: 'Notify if you see negative news about X' },
  { icon: Calendar, title: 'Event Changes', description: 'Track updates to event details' },
  { icon: Rocket, title: 'Competitor Updates', description: 'Monitor new product launches' },
  { icon: FileText, title: 'Policy Changes', description: 'Watch for updates to terms or policies' },
  { icon: Bell, title: 'Custom Alerts', description: 'Set up alerts for any change' },
];

function App() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [jobs, setJobs] = useState([]);
  const [newJob, setNewJob] = useState({
    url: '',
    info: '',
    frequency: '1440',
    status: 'Active',
  });
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [subscriptionLevel, setSubscriptionLevel] = useState('Unregistered');
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const theme = useTheme();
  const [authMode, setAuthMode] = useState('signin');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const fetchJobs = useCallback(async () => {
    if (!currentUser) {
      console.log('No authenticated user, skipping job fetch');
      return;
    }
    try {
      const token = await currentUser.getIdToken(true);  // Force token refresh
      console.log('Fetching jobs with token:', token);

      const jobsResponse = await fetch(`${API_URL}/data`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      console.log('Jobs response status:', jobsResponse.status);
      
      if (!jobsResponse.ok) {
        const errorText = await jobsResponse.text();
        console.error('Jobs response error:', errorText);
        throw new Error(`Failed to fetch jobs: ${jobsResponse.status} ${jobsResponse.statusText}`);
      }

      const jobsData = await jobsResponse.json();
      console.log('Fetched jobs:', jobsData);
      const nonDeletedJobs = jobsData.filter(job => job.status !== 'Deleted');
      setJobs(nonDeletedJobs);

      // Fetch user data separately
      const userResponse = await fetch(`${API_URL}/users/${currentUser.uid}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log('User response status:', userResponse.status);
      
      if (!userResponse.ok) {
        const errorText = await userResponse.text();
        console.error('User response error:', errorText);
        throw new Error(`Failed to fetch user data: ${userResponse.status} ${userResponse.statusText}`);
      }
      const userData = await userResponse.json();
      console.log('Fetched user data:', userData);
      setSubscriptionLevel(userData.subscriptionStatus || 'Unregistered');
      setUserEmail(userData.email || '');

    } catch (error) {
      console.error('Error when fetching data:', error);
      setNotification({
        open: true,
        message: 'Failed to fetch data. Please try again later.',
        severity: 'error',
      });
      setJobs([]);
      setSubscriptionLevel('Unregistered');
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
        setUserId(user.uid);
        setUserEmail(user.email);
        fetchJobs();
      } else {
        setCurrentUser(null);
        setUserId(null);
        setUserEmail('');
        setJobs([]);
      }
    });

    return unsubscribe;
  }, [fetchJobs, setCurrentUser]);

  const handleEmailPasswordAuth = async (e) => {
    e.preventDefault();
    if (!userEmail || !password) {
      setErrors({ auth: 'Please enter both email and password.' });
      return;
    }

    if (authMode === 'signup') {
      if (password !== confirmPassword) {
        setErrors({ confirmPassword: 'Passwords do not match.' });
        return;
      }
      if (!agreeToTerms) {
        setErrors({ terms: 'You must agree to the Terms of Service and Privacy Policy.' });
        return;
      }
    }

    try {
      if (authMode === 'signup') {
        const userCredential = await createUserWithEmailAndPassword(auth, userEmail, password);
        await sendEmailVerification(userCredential.user);
        setNotification({
          open: true,
          message: 'Account created successfully! Please check your email for verification.',
          severity: 'success',
        });
      } else {
        await signInWithEmailAndPassword(auth, userEmail, password);
        setNotification({
          open: true,
          message: 'Signed in successfully!',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Authentication error:', error);
      let errorMessage = 'Authentication failed. Please try again.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already in use. Please sign in or use a different email.';
      } else if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        errorMessage = 'Invalid email or password. Please try again.';
      }
      setNotification({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setNotification({
        open: true,
        message: 'Signed out successfully.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error signing out:', error);
      setNotification({
        open: true,
        message: 'Failed to sign out.',
        severity: 'error',
      });
    }
  };

  const deleteJob = async (id) => {
    try {
      const jobToDelete = jobs.find(job => job.id === id);
      if (jobToDelete) {
        const updatedJob = { ...jobToDelete, status: 'Deleted' };
        const response = await fetch(`${API_URL}/data/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedJob),
        });
        if (!response.ok) {
          throw new Error(`Failed to delete job: ${response.status} ${response.statusText}`);
        }
        setJobs((prevJobs) => prevJobs.filter(job => job.id !== id));
        setNotification({
          open: true,
          message: 'Job deleted successfully!',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error deleting job:', error);
      setNotification({
        open: true,
        message: 'Failed to delete job.',
        severity: 'error',
      });
    }
  };

  const toggleJobStatus = async (id) => {
    const jobToUpdate = jobs.find((job) => job.id === id);
    if (jobToUpdate) {
      const updatedJob = {
        ...jobToUpdate,
        status: jobToUpdate.status === 'Active' ? 'Stopped' : 'Active',
      };
      try {
        const response = await fetch(`${API_URL}/data/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedJob),
        });
        if (!response.ok) {
          throw new Error(`Failed to update job: ${response.status} ${response.statusText}`);
        }
        const result = await response.json();
        setJobs((prevJobs) => prevJobs.map((job) => (job.id === id ? result.job : job)));
        setNotification({
          open: true,
          message: `Job ${updatedJob.status === 'Active' ? 'started' : 'stopped'} successfully!`,
          severity: 'success',
        });
      } catch (error) {
        console.error('Error updating job status:', error);
        setNotification({
          open: true,
          message: 'Failed to toggle job status.',
          severity: 'error',
        });
      }
    }
  };

  const handleAddJob = async () => {
    const validationErrors = {};
    let processedUrl = newJob.url.trim();
    if (!processedUrl.startsWith('http://') && !processedUrl.startsWith('https://')) {
      processedUrl = 'https://' + processedUrl;
    }

    if (!isValidUrl(processedUrl)) {
      validationErrors.url = 'Please enter a valid URL (e.g., https://www.example.com)';
    }
    if (newJob.info.trim() === '') {
      validationErrors.info = 'Description cannot be empty';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (!currentUser) {
      setNotification({
        open: true,
        message: 'You must be signed in to add a job.',
        severity: 'error',
      });
      return;
    }

    const jobToSave = {
      url: processedUrl,
      info: newJob.info,
      frequency: newJob.frequency,
      status: 'Active',
    };

    try {
      console.log('Attempting to save job:', jobToSave);
      const token = await currentUser.getIdToken();
      console.log('Got token for job creation:', token);
      const response = await fetch(`${API_URL}/data`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(jobToSave),
      });
      console.log('Job creation response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Job creation error:', errorData);
        throw new Error(errorData.error || `Failed to save job: ${response.status}`);
      }
      
      const result = await response.json();
      console.log('Job creation successful:', result);
      setJobs((prevJobs) => [...prevJobs, result.job]);
      setNewJob({ url: '', info: '', frequency: '1440', status: 'Active' });
      setErrors({});
      setNotification({
        open: true,
        message: 'Job added successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error saving job:', error);
      setNotification({
        open: true,
        message: `Failed to add job: ${error.message}`,
        severity: 'error',
      });
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleSubscriptionSuccess = () => {
    fetchJobs();
    setNotification({
      open: true,
      message: 'Subscription successful! Thank you for subscribing.',
      severity: 'success',
    });
  };

  const canAddMoreJobs = useCallback(() => {
    const jobCount = jobs.filter(job => job.status !== 'Deleted').length;
    switch (subscriptionLevel) {
      case 'Basic':
        return jobCount < 3;
      case 'Pro':
        return jobCount < 5;
      case 'Unregistered':
      default:
        return jobCount < 1;
    }
  }, [subscriptionLevel, jobs]);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      setNotification({
        open: true,
        message: 'Signed in successfully with Google!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setNotification({
        open: true,
        message: 'Failed to sign in with Google. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleNotification = (notificationData) => {
    setNotification(notificationData);
  };

  useWebSocket(WS_URL, jobs, setJobs);

  const handleAuthModeChange = (event, newAuthMode) => {
    if (newAuthMode !== null) {
      setAuthMode(newAuthMode);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="md">
      <Box 
        component="a"
        href="https://webmonitor.fyi"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(2, 0),
          marginBottom: theme.spacing(4),
          borderBottom: `1px solid ${theme.palette.divider}`,
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <img 
          src="/logo512.png" 
          alt="WebMonitor.fyi Logo" 
          style={{ 
            width: '38px',  // Increased from 32px to 38px (approximately 20% larger)
            height: '38px', // Increased from 32px to 38px (approximately 20% larger)
            marginRight: theme.spacing(2) 
          }}
        />
        <Box>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              fontWeight: 'bold', 
              margin: 0,
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.75rem',
              },
            }}
          >
            webmonitor.fyi
          </Typography>
          <Typography 
            variant="subtitle1" 
            color="textSecondary"
            sx={{
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
              },
            }}
          >
            AI-Powered Website Monitor
          </Typography>
        </Box>
      </Box>

      {currentUser ? (
        <>
          {canAddMoreJobs() ? (
            <Box mb={4}>
              <JobForm
                newJob={newJob}
                setNewJob={setNewJob}
                handleAddJob={handleAddJob}
                errors={errors}
                subscriptionLevel={subscriptionLevel}
                currentJobs={jobs.length}
              />
            </Box>
          ) : null}

          <Box mb={2}>
            <Typography variant="h5" component="h2" gutterBottom>
              Your Monitoring Jobs
            </Typography>
          </Box>
          {jobs.length === 0 ? (
            <Typography>You don't have any monitoring jobs yet.</Typography>
          ) : (
            <JobList
              jobs={jobs}
              setJobs={setJobs}
              toggleJobStatus={toggleJobStatus}
              deleteJob={deleteJob}
              subscriptionLevel={subscriptionLevel}
              onNotification={handleNotification}
              userId={userId}
              onSubscriptionSuccess={handleSubscriptionSuccess}
            />
          )}

          {/* Increase top and bottom margins to create even more space */}
          <Box mb={8} mt={8}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" component="h2">Example Prompts / Use Cases</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {useCases.map((useCase, index) => {
                    const IconComponent = useCase.icon;
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                          <IconComponent style={{ marginRight: '8px', marginTop: '4px' }} />
                          <div>
                            <Typography variant="subtitle1" component="h3">
                              {useCase.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {useCase.description}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                <Typography variant="caption" display="block" gutterBottom sx={{ mt: 2 }}>
                  * Limited to monitoring initial pages and cannot handle websites requiring login, deep scrolling, or navigation.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb={2} textAlign="right">
            <Button variant="outlined" color="secondary" onClick={handleSignOut}>
              Sign Out
            </Button>
          </Box>

          <Typography variant="body1">
            Subscription Level: {subscriptionLevel}
            {subscriptionLevel !== 'Unregistered' && (
              <>
                {' '}
                <Link
                  href="https://billing.stripe.com/p/login/4gw18a977aOT796cMM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Manage
                </Link>
              </>
            )}
          </Typography>
        </>
      ) : (
        // Content for non-authenticated users
        <Box mb={4}>
          <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, margin: 'auto' }}>
            <ToggleButtonGroup
              value={authMode}
              exclusive
              onChange={handleAuthModeChange}
              aria-label="authentication mode"
              fullWidth
              sx={{ mb: 3 }}
            >
              <ToggleButton value="signin" aria-label="sign in">
                Sign In
              </ToggleButton>
              <ToggleButton value="signup" aria-label="sign up">
                Sign Up
              </ToggleButton>
            </ToggleButtonGroup>

            <Typography variant="h5" gutterBottom align="center">
              {authMode === 'signup' ? 'Create Account' : 'Welcome Back'}
            </Typography>
            <form onSubmit={handleEmailPasswordAuth}>
              <TextField
                label="Email Address"
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                error={Boolean(errors.email)}
                helperText={errors.email}
                fullWidth
                margin="normal"
                required
              />
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={Boolean(errors.password)}
                helperText={errors.password}
                fullWidth
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {authMode === 'signup' && (
                <>
                  <TextField
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreeToTerms}
                        onChange={(e) => setAgreeToTerms(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I agree to the <Link href="/terms-and-conditions.html" target="_blank">Terms of Service</Link> and <Link href="/privacy-policy.html" target="_blank">Privacy Policy</Link>
                      </Typography>
                    }
                  />
                  {errors.terms && (
                    <Typography color="error" variant="body2">
                      {errors.terms}
                    </Typography>
                  )}
                </>
              )}
              <Button 
                variant="contained" 
                color="primary" 
                type="submit"
                fullWidth
                sx={{ mt: 2, mb: 2 }}
              >
                {authMode === 'signup' ? 'Sign Up' : 'Sign In'}
              </Button>
            </form>
            {authMode === 'signin' && (
              <Link
                component="button"
                variant="body2"
                onClick={() => setShowForgotPassword(true)}
                sx={{ display: 'block', textAlign: 'center', mt: 1, cursor: 'pointer' }}
              >
                Forgot Password?
              </Link>
            )}
            <Divider sx={{ my: 2 }}>OR</Divider>
            <Box display="flex" justifyContent="center">
              <GoogleButton
                onClick={handleGoogleSignIn}
                type="light"
              />
            </Box>
          </Paper>
        </Box>
      )}

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>

      {/* Footer updated with Contact link using absolute URL */}
      <Box component="footer" sx={{ mt: 4, py: 3, borderTop: 1, borderColor: 'divider' }}>
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} WebMonitor.fyi. All rights reserved.
          {' | '}
          <Link href="https://app.webmonitor.fyi/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </Link>
          {' | '}
          <Link href="https://app.webmonitor.fyi/privacy-policy.html" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
          {' | '}
          <Link href="https://app.webmonitor.fyi/feature-roadmap.html" target="_blank" rel="noopener noreferrer">
            Feature Roadmap
          </Link>
          {' | '}
          <Link href="https://webmonitor.fyi/contact.html" target="_blank" rel="noopener noreferrer">
            Contact
          </Link>
        </Typography>
      </Box>

      {showForgotPassword && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ForgotPassword 
            onBack={() => setShowForgotPassword(false)} 
            setNotification={setNotification}
          />
        </Box>
      )}
    </Container>
  );
}

// Wrap the App component with AuthProvider
function AppWithAuth() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWithAuth;
