import React from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, Tooltip } from '@mui/material';

function JobForm({ newJob, setNewJob, handleAddJob, errors, subscriptionLevel, currentJobs }) {
  const getJobLimit = () => {
    switch (subscriptionLevel) {
      case 'Basic':
        return 3;
      case 'Pro':
        return 5; // Corrected from 10 to 5
      case 'Unregistered':
      default:
        return 1;
    }
  };

  const jobLimit = getJobLimit();

  // {{ edit_1 }}
  const frequencyOptions = [
    { value: '1440', label: 'Every day' },
    { value: '720', label: 'Every 12h' },
    { value: '360', label: 'Every 6h' },
  ];

  // ... existing code ...

  // {{ edit_2 }}
  const renderFrequencyOption = (option) => {
    if (subscriptionLevel === 'Pro') {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    } else {
      // For Basic and Unregistered users, only allow 'Every day'
      if (option.value === '1440') {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      } else {
        return (
          <Tooltip title="Upgrade to Pro for more frequent monitoring" arrow>
            <span>
              <MenuItem disabled value={option.value}>
                {option.label} (PRO)
              </MenuItem>
            </span>
          </Tooltip>
        );
      }
    }
  };

  return (
    <form onSubmit={(e) => { e.preventDefault(); handleAddJob(); }}>
      <TextField
        label="Website URL"
        placeholder="https://www.example.com"
        value={newJob.url}
        onChange={(e) => setNewJob({ ...newJob, url: e.target.value })}
        error={Boolean(errors.url)}
        helperText={errors.url}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Content to Monitor"
        placeholder="e.g., new product releases"
        value={newJob.info}
        onChange={(e) => setNewJob({ ...newJob, info: e.target.value })}
        error={Boolean(errors.info)}
        helperText={errors.info}
        fullWidth
        margin="normal"
        multiline
        minRows={4}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Frequency</InputLabel>
        <Select
          value={newJob.frequency}
          onChange={(e) => setNewJob({ ...newJob, frequency: e.target.value })}
          label="Frequency"
          disabled={subscriptionLevel === 'Unregistered'}
        >
          {frequencyOptions.map(renderFrequencyOption)}
        </Select>
      </FormControl>

      {subscriptionLevel === 'Unregistered' && (
        <Typography variant="body2" color="textSecondary">
          Upgrade to choose monitoring frequency
        </Typography>
      )}

      <Typography variant="body2" color="textSecondary">
        {`Jobs: ${currentJobs} / ${jobLimit}`}
      </Typography>

      <Button 
        variant="contained" 
        color="primary" 
        type="submit"
        disabled={currentJobs >= jobLimit}
      >
        Add Job
      </Button>
    </form>
  );
}

export default JobForm;
